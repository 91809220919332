.main {
  flex-grow: 1;
  width: 100%;
}

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  width: 100%;
}
