.footer {
  display: flex;
}

.left-block {
  align-self: flex-start;
}

.right-block {
  margin-left: auto;
  display: flex;
  align-items: center;
}
