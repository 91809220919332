.outer-container {
  width: 100%;
}

:global(body.light) .outer-container {
  background-color: #fdf4f3;
}

:global(body.dark) .outer-container {
  background-color: #2e2c2c;
}

.container {
  display: flex;
  flex-wrap: wrap;
  padding: 60px 0;
}

.img-container {
  display: flex;
  flex: 1 1 40%;
  justify-content: center;
}

.img {
  align-self: center;

  margin: 20px;
  max-width: 200px;
}

.text-container {
  flex: 1 1 60%;
}
